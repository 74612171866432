import React from 'react'
import { XStack } from 'tamagui'
import { BoldTextPrimary, DefaultTextPrimary } from '@centrito/ui/src/components/text'

interface HighlightTextProps {
  text: string
  boldText: string
}

export const HighlightText: React.FC<HighlightTextProps> = ({ text, boldText }) => {
  const index = text.toLowerCase().trim().indexOf(boldText.toLowerCase().trim())

  const hasSpaceLeftSide = text[index - 1] === ' '
  const hasSpaceRightSide = text[index + boldText.length] === ' '

  if (index === -1) {
    return (
      <DefaultTextPrimary
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        fontSize={14}
      >
        {text}
      </DefaultTextPrimary>
    )
  }

  const before = text.slice(0, index)
  const bold = text.slice(index, index + boldText.length)
  const after = text.slice(index + boldText.length)

  return (
    <XStack
      width="100%"
      style={{
        textOverflow: 'ellipsis',
        display: 'inline-block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'left',
      }}
    >
      <DefaultTextPrimary fontSize={14} marginRight={hasSpaceLeftSide ? 4 : 0}>
        {before}
      </DefaultTextPrimary>
      <BoldTextPrimary fontSize={14}>{bold}</BoldTextPrimary>
      <DefaultTextPrimary fontSize={14} marginLeft={hasSpaceRightSide ? 4 : 0}>
        {after}
      </DefaultTextPrimary>
    </XStack>
  )
}
