import React from 'react'
import { useLink } from 'solito/link'
import { HighlightText } from '@centrito/ui/src/components/HighlightText'
import { BaseButton } from '@centrito/ui/src/components/button/BaseButton'

export interface SearchButtonProps {
  linkProps: {
    pathname: string
    query: {
      searchTerm: string
      merchantName?: string
    }
  }
  productName: string
  searchTerm: string
  onPress: () => void
}

export const SearchSuggestionButton: React.FC<SearchButtonProps> = ({
  productName,
  searchTerm,
  linkProps,
  onPress,
}) => {
  const suggestionLinkProps = useLink({
    href: {
      pathname: linkProps.pathname,
      query: { ...linkProps.query },
    },
  })
  return (
    <BaseButton
      backgroundColor={'#FFFFFF'}
      display="flex"
      fontSize={14}
      hasShadow={false}
      justifyContent="flex-start"
      {...suggestionLinkProps}
      onPress={(): void => {
        onPress()
        suggestionLinkProps.onPress()
      }}
    >
      <HighlightText text={productName} boldText={searchTerm} />
    </BaseButton>
  )
}
