import type { SVGProps } from 'react'

const MagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" {...props}>
    <path
      stroke="#7F7F7F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 16.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12ZM15 15.5l5 5"
    />
  </svg>
)
export default MagnifyingGlass
