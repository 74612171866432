import * as React from 'react'
import type { SVGProps } from 'react'

interface ShoppingCartSvgProps {
  props?: SVGProps<SVGSVGElement>
  stroke?: string
  width?: number
  height?: number
}
const ShoppingCart: React.FC<ShoppingCartSvgProps> = ({
  stroke = '#4646F5',
  width = 24,
  height = 25,
  props,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    {...props}
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 20.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM7 20.5a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM3 3.5h.768a1 1 0 0 1 .983.818L7 16.5M19.743 6.5H5.5l1.5 10h10.897a1 1 0 0 0 .974-.775l1.846-8a1 1 0 0 0-.974-1.225Z"
    />
  </svg>
)
export default ShoppingCart
